import React, { useState, useEffect, useRef } from "react";
import {
  SpinnerContainer,
  DashboardContainer,
  Buttons,
} from "components/Admin/styles";
import Spinner from "react-spinkit";
import {
  fetchCohortMetrics,
  fetchUserDataByUid,
  fetchUserData,
  fetchWebinarData,
} from "../../api/db";
import Label from "components/Elements/Label";

export default function cohortMetrics() {
  const [cohortMetrics, setCohortMetrics] = useState([]);
  const [cohortNumber, setCohortNumber] = useState(3);
  const [attendance, setAttendance] = useState({});
  let once = useRef(false);

  function parseTimestamp(timestamp) {
    let date = new Date(timestamp);
    var month = (date.getMonth() + 1) % 12;
    var dayOfMonth = date.getDate();
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime =
      month +
      "/" +
      dayOfMonth +
      " | " +
      hours +
      ":" +
      minutes.substr(-2) +
      ":" +
      seconds.substr(-2);
    return formattedTime;
  }

  useEffect(() => {
    let cohortNumber = location.pathname.slice(22);
    setCohortNumber(cohortNumber);
    let cohort = "";
    if (cohortNumber != "") {
      cohort = "cohort " + cohortNumber;
    }

    if (cohortNumber != "" && !once.current) {
      fetchCohortMetrics(cohortNumber).once("value", (snapshot) => {
        let metrics = snapshot.val();
        if (metrics) {
          metrics = Object.entries(metrics);
          metrics.map((webinarMetrics, indexMetrics) => {
            // map through to get each webinar's metrics
            let webinarName = webinarMetrics[0];
            fetchWebinarData(webinarName, cohortNumber).once(
              "value",
              (snapshot) => {
                // get webinar Meta data
                let webinarMetaData = snapshot.val();
                let timestamp = webinarMetaData.timestamp;
                let timestampEnd = webinarMetaData.timestampEnd;
                Object.entries(webinarMetrics[1]).map((uidData, indexUid) => {
                  // map to get each uid's data
                  let uid = uidData[0];
                  fetchUserDataByUid(uid).once("value", (snapshot) => {
                    let parsedUidData = Object.values(uidData[1]);
                    if (typeof parsedUidData[0] == "object") {
                      // check becuase previous metrics were not in this form
                      let found = false;
                      parsedUidData.map((session, index) => {
                        let timeDiff = null;
                        if (session.timeEnded) {
                          timeDiff = session.timeEnded - session.timeStarted;
                        }
                        if(session.timeStarted) {
                          if (!timeDiff || timeDiff > 60000) {
                            // stayed more than a minute
                            let start = timestamp - 36000000;
                            let end = timestampEnd + 36000000;
                            if (
                              session.timeStarted > start &&
                              session.timeStarted < end &&
                              !found
                            ) {
                              found = true; // in case someone came to the page multiple times during a workshop
                              once.current = true; // to avoid useEffect running multiple times due to all the state changes
                              setAttendance((attendance) => ({
                                ...attendance,
                                [webinarName]: attendance[webinarName]
                                  ? attendance[webinarName] + 1
                                  : 1,
                              }));
                            }
                          }
                        }
                      });
                    }
                  });
                });
              }
            );
          });
        }
      });
    }
  }, [once]);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href={cohortNumber && `/admin/cohort/${cohortNumber}`}>
          {" "}
          &larr; Back
        </a>{" "}
      </Label>
      <br />
      <br />
      {attendance != {} ? (
        <>
          <h2>Metrics</h2>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>webinar</th>
                <th>attendance</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(attendance).map((webinar, index) => (
                <tr key={webinar[0]}>
                  <td>{index}</td>
                  <td>{webinar[0]}</td>
                  <td>{attendance[webinar[0]] ? attendance[webinar[0]] : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}
    </DashboardContainer>
  );
}
